<template>
  <div class="error404">
    <h1>Restricted Access</h1>
  </div>
</template>

<script>
export default {
  setup() {
    
  },
}
</script>
